'use client';

import React, { useEffect } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { isPWA } from 'src/utils/isPWA';

const PWA_STORED_URL_KEY = 'pwa-stored-url';

const storeUrl = (url: string) => {
  localStorage.setItem(PWA_STORED_URL_KEY, url);
};

const readStoredUrl = () => {
  return localStorage.getItem(PWA_STORED_URL_KEY);
};

const useStoredUrl = () => {
  const pathname = usePathname();
  const [storedUrl] = React.useState<string | null>(readStoredUrl);

  React.useEffect(() => {
    if (isPWA) {
      storeUrl(pathname);
    }
  }, [pathname]);

  return storedUrl;
};

export function RestoreUrl(props: React.PropsWithChildren) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const storedUrl = useStoredUrl();

  useEffect(() => {
    const shouldRedirect = storedUrl && isPWA;
    if (shouldRedirect && window.location.pathname !== storedUrl) {
      router.replace(storedUrl);
    }
  }, []);

  // this is a workaround to prevent infinite loop when user opens PWA
  if (isPWA && storedUrl && searchParams.get('source') === 'pwa') {
    return null;
  }

  return <>{props.children}</>;
}
